<template>
    <vue-good-table
      id="table_investments"
      :columns="columns"
      :rows="commissions"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: search,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'immobileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.immobileName }}</span>
        </span>

        <span v-else-if="props.column.field === 'value'" class="text-nowrap">
          <span class="text-nowrap">{{ formatCurrencyPrice(props.row.value) }}</span>
        </span>

        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.date }}</span>
        </span>

        <span v-else-if="props.column.field === 'status'" class="text-nowrap">
          
                <b-badge :variant="statusInvestmentVariant(props.row.status)">
                   {{ statusInvestmentVariantName(props.row.status) }}
                </b-badge>  
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
</template>

<script>
import {  BInputGroupText, BCardText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import Loader from '../../components/Loader.vue'
import formatter from '@/mixins/formatter'
import BrokerInvestmentService from '@/services/Investment/BrokerInvestmentService'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import { codeBasic } from '@/views/codeTable'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    VueGoodTable,
    BCol,BInputGroupText, BInputGroupAppend, BPagination, 
    BFormSelect, BBadge, BIcon, BCollapse, BButton, BFormGroup, BFormInput, BInputGroup,
    BCardText,
    BLink,
    BRow,
    Loader,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,    
    'b-toggle': VBToggle
  },
   data: () => ({
        commissions: [],
        pageLength: 10,
        dir: false,
        codeBasic,
        filters: {},
        search: '',
        columns: [],
        config: {
            dateFormat: 'd/m/Y',
            locale: Portuguese,
        },
      }),
    mixins:[formatter],
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
            this.dir = true
            return this.dir
            }
            this.dir = false
            return this.dir
        },
    
        statusInvestmentVariantName() {
        const statusName = {
            1: 'Pago',
            2: 'Pendente'
        }
        return status => statusName[status]
        },

        statusInvestmentVariant() {
          const statusColor = {
          1: 'light-success',
          2: 'light-primary'
        }
        return status => statusColor[status]
      }, 
    },

  methods:{
    async getComissions(){
        const result = (await BrokerInvestmentService.getDashboardBroker()).data.result
        this.commissions = result.commissions
    },
    
    getColumn() {
      this.columns = [
        {
          label: 'Imóvel',
          field: 'immobileName',
        },
        {
          label: 'Valor',
          field: 'value',
        },
        {
          label: 'Data',
          field: 'date',
        },
        {
          label: 'Investidor',
          field: 'investorName',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: '',
          field: 'action',
        }
      ]
    },

  },

    mounted() {
        this.getComissions()
        this.getColumn()
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
#table_investments tr {
  border: 0;
  display: block;
  margin: 15px 3px;
  border: 1px #fff solid;
  border-radius: 10px;
}

#table_investments thead {
  display: none;
}

#table_investments td {
  padding: 5px;
}
#table_investments tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

#table_investments  .vgt-table.bordered td,  #table_investments .vgt-table.bordered th {
  border: #fff !important;
}

//#table_investments table.vgt-table td {
//  padding: 0.75em 0.75em 0.75em 0.75em !important;
//}

#table_investments .vgt-left-align {
  text-align: left !important;
}

#table_investments table.vgt-table td {
  vertical-align: baseline !important;
  color: #6e6b7b;
}

#table_investments th:first-child { min-width: 30px !important;  }
#table_investments th { min-width: 250px !important;  }
#table_investments th:last-child { min-width: 100px !important;  }

#table_investments table tbody td :first-child { min-width: 30px !important;  }
#table_investments table tbody td  { min-width: 250px !important;  }
#table_investments table tbody td :last-child { min-width: 100px !important;  }

#table_investments table.vgt-table tbody {
  font-size: 13px;
}
#table_investments table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}
#table_investments .vgt-table th.line-numbers, #table_investments .vgt-table th.vgt-checkbox-col {
  padding: 0 2.75em 0 0.75em;
}
#table_investments a { color: #828282; }
#table_investments a:hover { color: var(--color); }
</style>